
              @import "@/assets/css/variables.scss";
            




































































































.forget_password_form_wrapper {
  .title {
    font-size: 24px;
    color: #09090D;
    line-height: 36px;
    font-weight: 700;
    text-align: center;
  }

  .tip {
    font-size: 12px;
    color: $text-secondary;
    margin: 22px 0 82px 0;
    text-align: center;
  }

  .login_label {
    font-size: 16px;
    color: $text-primary;
    line-height: 24px;
    font-weight: 700;
    margin-bottom: 8px;
    @include rtl-sass-prop-dual(text-align, left, text-align, right);
  }

  .verification-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }

  .submit_btn {
    width: 100%;
    height: 40px;
    font-size: 14px;
    font-weight: 700;
    color: $white;
    border-radius: 8px;
    background: $secondary;
    margin-top: 16px;
  }

  .error {
    font-size: 14px;
    color: $danger;
    margin-top: 24px;
    text-align: center;
  }

  .el-button:active {
    border: none;
  }

  .el-input__inner {
    border-radius: 8px;
    padding: 8px 16px;
  }

  .el-form-item:nth-child(1) {
    margin-top: 24px;
  }

  .el-input__inner:-webkit-autofill {
    box-shadow: 0 0 0px 1000px white inset !important;
  }
}

.forget_password_success {
  text-align: center;

  .success_text {
    font-size: 14px;
    line-height: 22px;
    color: $text-primary;
    margin-top: 16px;
  }

  .email {
    font-size: 14px;
    line-height: 22px;
    color: $text-link;
    margin-top: 16px;
  }
}
